<template>
<div class="regionsList" >
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="col-sm-6">
        <label for="">Фильтрация по странам</label>
        <a-select
            mode="multiple"
            placeholder="Выберите страну"
            style="width: 450px"
            optionLabelProp="label"
            optionFilterProp="label"
            @change="handleChange"
            @search="searchCountry"
          >
          <a-select-option v-for="country in countries" :key="country.id" :value="country.id" :label="country.title">
            {{country.title}}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-sm-6">
        <label for="">Поиск по названию</label>
        <template>
          <a-input placeholder="Название региона"
          v-model="itemName"
          @change="searchRegion"
          />
        </template>
      </div>
    </div>
  </div>
  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Регионы</h5>
      <a @click="createRegionModalShow">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
      <table class="table table-striped" id="regionsList">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Наименование</th>
            <th scope="col">Страна</th>
            <th scope="col">Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in data">
            <th scope="row">{{index+1}}</th>
            <td>{{value.title}}</td>
            <td v-if="value.country">{{value.country.title}}</td> <td v-else>--</td>
            <td>
              <a  @click="showModal(value)">
                <span class="border rounded border-primary m-1 p-2 text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </span>
              </a>
              <a @click="editModal(value)">
                <span class="border rounded border-success m-1 p-2 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </span>
              </a>
              <a @click="deleteRegion(index, value)">
                <span class="border rounded border-danger m-1 p-2 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show Modal -->
<a-modal v-model="visible" title="Детали"  footer="">
  <table class="table table-striped" >
    <tbody>
      <tr>
        <td>ID</td>
        <td>{{item.id}}</td>
      </tr>
      <tr>
        <td>Наименование</td>
        <td>{{item.title}}</td>
      </tr>
      <tr>
        <td>Страна</td>
        <td v-if="item.country">{{item.country.title}}</td> <td v-else>--</td>
      </tr>
      <tr>
        <td>Количество городов</td>
        <td v-if="item.cities">{{item.cities.length}}</td> <td v-else>0</td>
      </tr>
      <tr>
        <td>Количество филиалов</td>
        <td v-if="item.branches">{{item.branches.length}}</td> <td v-else>0</td>
      </tr>
    </tbody>
  </table>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail1">Наименование</label>
    <input type="email" class="form-control" v-model="item.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>

  <div class="form-group" v-if="editCountryInRegions">
    <label for="exampleInputEmail">Страна</label>
    <a-auto-complete  class="w-100" :class="{'is-invalid': validateErrors.id_country}" placeholder="Выберите страну" @search="handleSearch" @change="changeHand">
      <template slot="dataSource">
        <a-select-option class="form-control" v-for="val in dataSource" :key="val.id.toString()" >
          {{ val.title }}
        </a-select-option>
      </template>
    </a-auto-complete>
    <div class="invalid-feedback" v-for="value in validateErrors.id_country"> {{value}} </div>
  </div>
  <div class="form-group" v-else>
    <label for="exampleInputEmail1">Страна</label>
    <input type="hidden" v-model="item.id_country">
    <div class="d-flex">
      <input type="email" class="form-control" v-model="item.country.title" disabled :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
      <button class="btn btn-info" @click="editCountryInRegions=true; item.id_country=0">Изменить</button>
    </div>
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="editRegion">Изменить</button>
  </div>
</a-modal>

<!-- Create Modal -->
<a-modal v-model="visibleCreateModal" title="Добавить новую"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>

  <div class="form-group">
    <label for="exampleInputEmail">Страна</label>
    <a-auto-complete  class="w-100" v-model="newItem.id_country" :class="{'is-invalid': validateErrors.id_country}" placeholder="Выберите страну" @search="handleSearch">
      <template slot="dataSource">
        <a-select-option class="form-control" v-for="val in dataSource" :key="val.id.toString()">
          {{ val.title }}
        </a-select-option>
      </template>
    </a-auto-complete>
    <div class="invalid-feedback" v-for="value in validateErrors.id_country"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="createRegion">Изменить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="delItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'

const data = '';
export default {
  name : 'User',
  data() {
    return {
      itemName: '',
      dataSource: [],
      countries: [],
      countriesForFilter: {
        id: []
      },
      item: {
        country: []
      },
      newItem: {
        title: '',
        id_country: ''
      },

      ind: -10,
      visible: false,
      visibleEditModal: false,
      visibleDeleteModal: false,
      visibleCreateModal: false,

      editCountryInRegions: false,
      data,
      currentPage: 1,
      totalResults: 0,
      scrolled: false,
      old_height: 0,
      last_page: 1,
      load: true,
      validateErrors: []
    };
  },
  mounted: function(){
   axios.get('/international/regions').then((response)=>{
       if(response.status == 200){
         this.data = response.data.data;
         this.totalResults = response.data.total;
         this.load = false;
         this.last_page = response.data.last_page;
       }else if(response.status == 204){
         this.$message.error(
           'Регионов не найдено',
           3
         );
         this.load = false;
       }
    }).catch((error)=>{
      if(error.response.status===401){
          this.$router.push('/pages/login')
          this.load = false;
      }else if(error.response.status == 500){
        this.$message.error(
          'Ошибка сервера',
          3
        );
        this.load = false
      }
    });

    axios.get('/international/countries').then((response)=>{
        if(response.status == 200){
          this.countries = response.data.data
        }
     }).catch((error)=>{
       if(error.response.status===401){
           this.$router.push('/pages/login')
           this.load = false;
       }
     });

    window.onscroll = () => {
        let height = document.getElementById('regionsList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.itemName.length>1){
            if(this.old_height!=height){
              this.old_height = height;
              let limit = this.data.length/this.$store.state.paginateCount + 1;
              if(limit <= this.last_page){
                this.load = true;
                axios.post('/international/regions/autocomplete/search/with/paginate', this.countriesForFilter, {params: {query: this.itemName, page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.$message.error(
                      'Регионов не найдено',
                      3
                    );
                    this.load = false
                  }
                }).catch(error => {
                  this.load = false
                });
              }
            }
          }else{
            if(this.countriesForFilter.id.length==0){
              if(this.old_height!=height){
                this.old_height = height;
                let limit = this.data.length/this.$store.state.paginateCount + 1;
                if(limit <= this.last_page){
                  this.load = true;
                  axios.get('/international/regions', {params: {page: limit}}).then(response => {
                    if(response.status == 200){
                        this.totalResults = response.data.total;
                        this.data = this.data.concat(response.data.data);
                        this.load = false
                    }
                  }).catch(error => {
                    this.load = false
                  });
                }
              }
            }else{
              if(this.old_height!=height){
                this.old_height = height;
                let limit = this.data.length/this.$store.state.paginateCount + 1;
                if(limit <= this.last_page){
                  this.load = true;
                  axios.post('/international/regions/filter/search', this.countriesForFilter,  {params: {page: limit}}).then(response => {
                    if(response.status == 200){
                      setTimeout(() => {
                        this.totalResults = response.data.total;
                        this.data = this.data.concat(response.data.data);
                        this.load = false
                      }, 1000);
                    }
                  }).catch(error => {
                    this.load = false
                  });
                }
              }
            }
          }

        }
    };

  },
  methods: {
    searchCountry(value){
      if(value.length>1){
        axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
          if(response.status == 200){
            this.countries = response.data;
              this.regions = [];
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих стран',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
      }
    },
    searchRegion(){
      if(this.itemName.length>1){
        this.load = true;
        this.old_height = 0;
        axios.post('/international/regions/autocomplete/search/with/paginate', this.countriesForFilter, {params: {query: this.itemName}})
        .then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.data = [];
            this.totalResults = 0;
            this.load = false;
            this.last_page = 0;
            this.$message.error(
              'Нет соответствующего региона',
              3
            );
            this.load = false;
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
              'Нет соответствующего региона',
              3
            );
            this.load = false;
          }else if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }
        });
      }else if(this.itemName.length == 0){
        if(this.countriesForFilter.id.length > 0){
          axios.post('/international/regions/filter/search', this.countriesForFilter).then(response => {
            if(response.status == 200){
              this.data = response.data.data;
              this.totalResults = response.data.total;
              this.load = false;
              this.last_page = response.data.last_page;
            }else if(response.status == 204){
              this.$message.error(
                'Регионов не найдено',
                3
              );
              this.load = false;
            }
          }).catch(error => {
            if(error.response.status == 500){
              this.$message.error(
                'Ошибка сервера',
                3
              );
              this.load = false;
            }
          });
        }else{
          axios.get('/international/regions').then((response)=>{
              if(response.status == 200){
                this.data = response.data.data;
                this.totalResults = response.data.total;
                this.load = false;
                this.last_page = response.data.last_page;
              }else if(response.status == 204){
                this.$message.error(
                  'Регионов не найдено',
                  3
                );
                this.load = false;
              }
           }).catch((error)=>{
             if(error.response.status===401){
                 this.$router.push('/pages/login')
                 this.load = false;
             }else if(error.response.status == 500){
               this.$message.error(
                 'Ошибка сервера',
                 3
               );
               this.load = false
             }
           });
        }
      }
    },
    changeHand(value){
      this.item.id_country = parseInt(value);
    },
    handleSearch(value) {
        console.log(value)
        if(value.length>1){
          axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
            if(response.status == 200){
              this.dataSource = response.data;
            }
          }).catch(error => {
            if(error.response.status == 204){
              this.$message.error(
                    'Нет соответствующих стран',
                    3,
              );
            }
          })
        }

    },
    handleChange(value) {
      this.countriesForFilter.id = value;
      this.load = true;
      this.old_height = 0;
      if(this.countriesForFilter.id.length>0){
        axios.post('/international/regions/filter/search', this.countriesForFilter).then(response => {
          if(response.status == 200){
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
          }else if(response.status == 204){
            this.$message.error(
              'Регионов не найдено',
              3
            );
            this.load = false;
          }
        }).catch(error => {
          if(error.response.status == 500){
            this.$message.error(
              'Ошибка сервера',
              3
            );
            this.load = false;
          }
        });

      }else{
        axios.get('/international/regions').then((response)=>{
            this.data = response.data.data;
            this.totalResults = response.data.total;
            this.load = false;
            this.last_page = response.data.last_page;
         }).catch((error)=>{
           if(error.response.status===401){
               this.$router.push('/pages/login')
               this.load = false;
           }
         });
      }

    },
    popupScroll() {
      console.log('popupScroll');
    },
    showModal(val) {
      this.item = val;
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    editModal(val){
      this.validateErrors = [];
      this.item = val;
      this.visibleEditModal = true;
      this.editCountryInRegions = false;
    },
    closeEditModal(e){
      this.visibleEditModal = false
    },
    editRegion(){
      axios.put('/international/regions/'+this.item.id, this.item).then(response => {
        if(response.status==200){
          this.item.country.title = response.data.country.title
          console.log(response.data)
          console.log(response.data.country.title)
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );
        }
      }).catch(error => {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
        }
      });
    },
    deleteRegion(ind, val){
      this.item = val;
      this.ind = ind;
      this.visibleDeleteModal = true;
    },
    delItem(){
      this.visibleDeleteModal = false;
      axios.delete('/international/regions/'+this.item.id).then(response => {
         if(response.status==200){
          this.visibleEditModal = false
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.ind, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.visibleEditModal = false
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.visibleEditModal = false
          this.$message.error(
                error.response.data.error,
                3,
          );
        }
      });
    },
    createRegionModalShow(){
      this.visibleCreateModal = true;
      this.validateErrors = [];
    },
    createRegion(){
      axios.post('/international/regions', this.newItem).then(response => {
        if(response.status==201){
          this.visibleCreateModal = false
          // this.data = this.data.concat(response.data)
          this.newItem.title = ''
          this.$message.success(
                'Успешно добавлено',
                3,
          );

        }
        console.log(response.data)
      }).catch(error => {
        if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
        }
      });
    },
    removebyProperty(prop, val, multiple){
        for(var i= 0, L= this.length;i<L;i++){
            if(i in this && this[i][prop]=== val){
                this.splice(i, 1);
                if(!multiple) i= L;
            }
        }
        return this.length;
    }
  },
}
</script>
<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
</style>
